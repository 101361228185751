import React from 'react';
import './ServiceCenter.css';
import anydesk from '../ServiceCenter/mcw_anydesk.exe';
import iperius from '../ServiceCenter/mcw_iperius.exe';
import remotely from '../ServiceCenter/mcw_remotely.exe';
import { Helmet } from 'react-helmet';

function Fernwartung() {
  return (
    <div className="fernwartungs_center">
      <Helmet>
        <title>Fernwartungs Center</title>
        <meta name="description" content="Willkommen im Fernwartungscenter von MCW. Wählen Sie die passende Fernwartungsoption, um schnelle und sichere Unterstützung zu erhalten. Ideal für technische Hilfe und Beratung zu unseren Produkten." />
      </Helmet>
      <div className="fernwartungs_header">
        <h1>
          <br />
          MCW Fernwartung
        </h1>
        <p>Bitte wählen Sie Ihre Option</p>
      </div>
      <div className="service_formulare">
        <div className="formular_service_container">
          <div className="formular_box1" id="anydesk">
            <a href={anydesk} download="mcw_anydesk.exe">
              <img className="iper_logo_3" src="./img/any_2.png" alt="anydesk-icon" />
            </a>
          </div>
          <div className="formular_text">
            <a href={anydesk} download="mcw_anydesk.exe">
              <h4>Fernwartung: AnyDesk</h4>
            </a>
            <p>Verbinden Sie sich schnell und sicher über AnyDesk.</p>
          </div>
        </div>
        <div className="formular_service_container">
          <div className="formular_box1" id="remotely">
            <a href={remotely} download="mcw_remotely.exe">
              <img className="iper_logo_2" src="./img/remote.png" alt="remotely-icon" />
            </a>
          </div>
          <div className="formular_text">
            <a href={remotely} download="mcw_remotely.exe">
              <h4>Fernwartung: Remotely</h4>
            </a>
            <p>Verbinden Sie sich schnell und sicher über Remotely.</p>
          </div>
        </div>
        <div className="formular_service_container">
          <div className="formular_box1" id="iperius">
            <a href={iperius} download="mcw_iperius.exe">
              <img src="./img/iper_2.png" className="iper_logo" alt="iperius-icon" />
            </a>
          </div>
          <div className="formular_text">
            <a href={iperius} download="mcw_iperius.exe">
              <h4>Fernwartung: Iperius Remote</h4>
            </a>
            <p>Verbinden Sie sich schnell und sicher über Iperius.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fernwartung;
