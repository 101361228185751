import React, {useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import "./Header.css";
import { useLocation } from 'react-router-dom';
import Transition from 'react-transition-group/Transition'

function Header() {




const history = useHistory();



// custom hook to get the current pathname in React

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
}

const isProductActive = () => {

    const path = usePathname
    
    const activeElements = ['/eos', '/dental', '/termion', '/telemedizin'].filter(el => el === path())

    return activeElements.length > 0
} 



const [showMenu, setShowMenu] = useState(false)

let menu 

if(showMenu){
menu = 
<div className={`mobile_nav ${showMenu ? "open" : ""}`} style={{transition: 'opacity 1s ease-out'}}>
<div className="mobile_nav_header">
<img src="./img/cancel.svg" alt="cancel-icon" onClick={() => setShowMenu(!showMenu)}/>
</div>

<div className="mobile_nav_kategorie">
<h4>Allgemein</h4>
<hr/>
</div>
<div className="mobile_navigation"  onClick={() => setShowMenu(!showMenu)}>
<nav>
<Link className="nav_styling" to="/">Home</Link> 
<Link className="nav_styling" to="/service">Service Center</Link> 
<Link className="nav_styling" to="/team">Team</Link> 
</nav>
</div>
<div className="mobile_nav_kategorie">
<h4>Unsere Produkte</h4>
<hr/>
</div>
<div className="mobile_navigation"  onClick={() => setShowMenu(!showMenu)}>
<nav>
    <Link className="nav_styling" to="/eos">EosWin</Link> 
    <Link className="nav_styling" to="/dental">DentalPro</Link> 
     <Link className="nav_styling" to="/termion">Termion</Link> 
     <Link className="nav_styling" to="/telemedizin">Telemedizin</Link> 
     <a className="nav_styling" href="https://www.bookingdoc.at/" target="_blank" rel="noreferrer" >BookingDoc</a>
     <a className="nav_styling" href="https://www.befundkarte.at/" target="_blank" rel="noreferrer" >Befundkarte</a>
     <a className="nav_styling" href="http://www.notfallkarte.at/" target="_blank" rel="noreferrer" >Notfallkarte</a>

</nav>
</div>
</div>
}





    return (
        <div className="header">
           {menu}
            <div className="header_bar">

             <Link to="/">
                 <div className="header_logo">
                
                         <img src="./img/MCW_Logo.png" alt=""/>
                </div>
                </Link>
                <Link to="/">
                 <div className="header_logo2">
                
                         <img src="./img/Mcw_Logo_Mobile.png" alt=""/>
                </div>
                </Link>
                  <div className="header_left" >
                  
                  {/* <Link className={`link_button_ärzte ${usePathname() === "/" ? 'active' : '' }`} to="/">Home</Link>  */}
                     
                     


                        <div className="header_parent_wrapper">
                        <h4 className={`produkt_header_schrift ${isProductActive() ? 'active' : '' }`}>Produkte</h4>

                        <div className="dropdown_menu">
                    <nav>
                    <Link className="link_button_2" to="/eos">EosWin</Link> 
                    <Link className="link_button_2" to="/dental">DentalPro</Link> 
                    <Link className="link_button_2" to="/termion">Termion</Link> 
                    <Link className="link_button_2" to="/telemedizin">Telemedizin</Link> 
                        <a href="https://www.bookingdoc.at/" target="_blank" rel="noreferrer">BookingDoc</a>
                        <a href="https://www.befundkarte.at/" target="_blank" rel="noreferrer">Befundkarte</a>
                        <a href="http://www.notfallkarte.at/" target="_blank" rel="noreferrer">Notfallkarte</a>
                    </nav>
                       </div>
                        </div>
                        <Link className={`link_button_service ${usePathname() === "/service" ? 'active' : '' }`} to="/service">Service Center</Link>
                        <Link className={`link_button_fernwartung ${usePathname() === "/fernwartung" ? 'active' : '' }`} to="/fernwartung">Fernwartung</Link>
                        <Link className={`link_button_team ${usePathname() === "/team" ? 'active' : '' }`} to="/team">Team</Link>

                 </div>
              <div className="header_right">
              <Link className="link_button_2" to="/anfrage">Beratung vereinbaren</Link> 
              <a className="link_button_3" href="https://shop.mcw.at/" target="_blank" rel="noreferrer">Shop</a>
              </div>

              <div className="header_mobile">
                      <div className="header_mobile_right">
                              <div className="header_mobile_button" onClick={() => history.push("/kontakt")}>
                                     <h4>Kontakt</h4>
                                       <img src="./img/chat_icon.svg" alt="Chat-Icon"/>
                                 </div>
            <div className="header_mobile_menu">
                      <img src="./img/menu_icon_3.svg" alt="menu-icon" onClick={() => setShowMenu(!showMenu)}/>
            </div>
            </div>
        </div>
        </div>            
        
        </div>

        
    )
}

export default Header;
