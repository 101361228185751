import React, { useState } from 'react';
import './MesseStepper.css';  // Importiere die CSS-Datei

const MesseStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    ordination: '',
    email: '',
    phone: '',
    accepted: false
  });

  const options = [
    { name: 'EOS', image: './img/Eos_Logo.png' },
    { name: 'TermiOn', image: './img/Termion_Logo_Neu.png' },
    { name: 'DentalPro', image: './img/DentalPro_Logo.png'},
    { name: 'Cybershield', image: './img/cyber_shield_logo.webp' },
    { name: 'Telemedizin', image: './img/Telemedizin_Logo.png' },
    { name: 'Komplettlösung inkl. Hardware', image: './img/siegel.webp' }
  ];

  const handleOptionChange = (optionName) => {
    setSelectedOptions(prevSelected =>
      prevSelected.includes(optionName)
        ? prevSelected.filter(item => item !== optionName)
        : [...prevSelected, optionName]
    );
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleNext = () => {
    if (activeStep === 0 && selectedOptions.length > 0) {
      setActiveStep(prevStep => prevStep + 1);
    }
  };

  const isFormValid = () => {
    return formData.name && formData.ordination && formData.email;
  };

  return (
    <div className="messe-stepper-container">
      {activeStep === 0 ? (
        <div className="messe-step">
          <h2>Für welche Produkte interessieren Sie sich?</h2>
          <div className="messe-options-grid">
            {options.map(option => (
              <div
                key={option.name}
                className={`messe-option-container ${selectedOptions.includes(option.name) ? 'messe-selected' : ''}`}
                onClick={() => handleOptionChange(option.name)}
              >
                <div className="messe-option-img-container">
                  <img src={option.image} alt={option.name} />
                </div>
                <p>{option.name}</p>
                <img className="messe-check-img" src="./img/check_1.svg" alt="" />
              </div>
            ))}
          </div>
          <button className="messe-button" onClick={handleNext} disabled={selectedOptions.length === 0}>
            Weiter
          </button>
        </div>
      ) : (
        <div className="messe-step">
          <h2 style={{ marginBottom: '25px' }}>Kontakt Information</h2>
          <form>
            <div>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="messe-input"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Ordination:
                <input
                  type="text"
                  name="ordination"
                  value={formData.ordination}
                  onChange={handleInputChange}
                  className="messe-input"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="messe-input"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Telefon:
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="messe-input"
                />
              </label>
            </div>
            <div className="messe-checkbox-wrapper">
              <label>
                <input
                  type="checkbox"
                  name="accepted"
                  checked={formData.accepted}
                  onChange={handleInputChange}
                  className="messe-checkbox"
                />
                <p>
                  Ich akzeptiere dass meine Daten für Werbezewecke von MCW verarbeitet werden.
                </p>
              </label>
            </div>
            <button className="messe-button" type="submit" disabled={!isFormValid()}>
              Absenden
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MesseStepper;
